<template>
    <section class="content-wrapper">
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo.png">
                            </div>
                            <h4 class="text-center mt-4">Un code a été envoyé à votre adresse mail</h4>
                            <h6 class="font-weight-light text-center">Veuillez entrer le code envoyé</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <b-form-group id="token" label="Code" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="token"
                                  :state="$v.token.$dirty ? !$v.token.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                  disabled
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Veuillez entrer le bon code
                                </b-form-invalid-feedback>
                              </b-form-group>

                            <div class="mt-3">
                                <button class="btn btn-block btn-primary  btn-lg font-weight-medium auth-form-btn">Confirmer le code</button>
                            </div>
                            <div class="my-2 d-flex justify-content-between align-items-center">
                            </div>
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas reçu de code? <router-link to="/authentification" class="text-primary">Renvoyer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const axios = require('axios').default
import { validationMixin } from 'vuelidate'
import { required} from 'vuelidate/lib/validators'
import pageLoader from '../components/pageLoader.vue'
export default {
    name: 'signinConfirmation',
    components:{
        pageLoader
    },
    mixins: [validationMixin],
    data() {
        return {
            token: '',
            ulid:'',
            loadershow:false
        }
    },
    validations: {
        token: {
            required
        }
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
        if (this.$v.$anyError) {
            return
        }
        this.loadershow=true
        // vérification token
        axios.get('myinfos',{
            headers: {
                'X-AUTH-TOKEN': this.token
            }
        })
        .then(async res=> {
            this.ulid=res.data.ulid
            await this.putMyInfos(res)
            console.log({res});
            
        })
        .catch(()=> {
            alert('Le code que vous avez entré n`est pas correct')
            this.loadershow=false
            return
            })
        },
        // changement de l'is active
        putMyInfos(res) {
            axios.put(`myinfos/${this.ulid}`,{
                "isactive": true
            },
            {
               headers: {
                   "X-AUTH-TOKEN": this.token
               }
            })
            .then(resPutMyInfos => {
                    console.log(resPutMyInfos)
                    this.$store.dispatch('login',{
                    resmyinfo:res ,
                    reslogin:{
                        data:{
                            token:this.token,
                            pseudo:res.data.pseudo,
                            expires:'',
                            roles:res.data.roles,
                        }
                    }})
                this.$router.push ({
                name: 'home'
                })
                })
            .catch(errPutMyInfos=> {
                console.log({errPutMyInfos});
                alert ("Erreur")
                this.loadershow=false
            })
        }
    },
    mounted() {
        // this.ulid=this.$route.params.ulid
        this.ulid=localStorage.getItem('ulid')
        this.token = this.$route.params.token
        if(this.token){
            this.handleSubmit()
        }
    }
}
</script>